<template>
	<div class="page">
		<div class="person">
			
			<div class="person_left">
				<div class="person_title">
					<div class="person_img">
						<img src="../../assets/mycenter/person.png">
					</div>
					<div class="person_title_name">{{$t('title.personCenter')}}</div>
				</div>
				<div class="all_center">
					<div class="center_item" v-for="(item,index) in personList" :key="index" @click="itemClick(index)">
						<div class="center_item_img" v-show="curCenterIndex==index" :class="curCenterIndex==index?'none':''">
							<!-- <img src="../../assets/person/item_select.png"> -->
						</div>
						<div :class="['center_item_name',curCenterIndex==index?'select':'un_select']">{{item}}</div>
						<img src="../../assets/mycenter/active_item.png" class="jiantou" v-if="curCenterIndex==index">
						<img src="../../assets/mycenter/item.png" class="jiantou" v-else>
					</div>
				</div>
				<div class="set">
					<div class="set_img">
						<img src="../../assets/person/set.png">
					</div>
					<div class="set_title">
						{{$t('title.set')}}
					</div>
				</div>
				<div class="all_center">
					<div  class="center_item" @click="itemClick2(0)">
						<div class="center_item_img" v-show="curSetIndex==0" :class="curSetIndex==0?'none':''">
							<!-- <img src="../../assets/person/item_select.png"> -->
						</div>
						<div :class="['center_item_name',curSetIndex==0?'select':'un_select']">
							{{$t('set.phoneAuthentication')}}
						</div>
						<img src="../../assets/mycenter/active_item.png" class="jiantou" v-if="curSetIndex==0">
						<img src="../../assets/mycenter/item.png" class="jiantou" v-else>
					</div>
					<!-- <div v-if="$store.state.userinfo.user_email" class="center_item" @click="itemClick2(1)">
						<div class="center_item_img" v-show="curSetIndex==1" :class="curSetIndex==1?'none':''">
						</div>
						<div :class="['center_item_name',curSetIndex==1?'select':'un_select']">
							{{$t('set.emailAuthentication')}}
						</div>
						<img src="../../assets/mycenter/active_item.png" class="jiantou" v-if="curSetIndex==1">
						<img src="../../assets/mycenter/item.png" class="jiantou" v-else>
					</div> -->
					<div  class="center_item" @click="itemClick2(2)">
						<div class="center_item_img" v-show="curSetIndex==2" :class="curSetIndex==2?'none':''">
						</div>
						<div :class="['center_item_name',curSetIndex==2?'select':'un_select']">
							{{$t('set.editPhone')}}
						</div>
						<img src="../../assets/mycenter/active_item.png" class="jiantou" v-if="curSetIndex==2">
						<img src="../../assets/mycenter/item.png" class="jiantou" v-else>
					</div>
					<!-- <div v-if="$store.state.userinfo.user_email" class="center_item" @click="itemClick2(3)">
						<div class="center_item_img" v-show="curSetIndex==3" :class="curSetIndex==3?'none':''">
						</div>
						<div :class="['center_item_name',curSetIndex==3?'select':'un_select']">
							{{$t('set.editEmail')}}
						</div>
						<img src="../../assets/mycenter/active_item.png" class="jiantou" v-if="curSetIndex==3">
						<img src="../../assets/mycenter/item.png" class="jiantou" v-else>
					</div>
					<div v-if="!$store.state.userinfo.mobile" class="center_item" @click="itemClick2(4)">
						<div class="center_item_img" v-show="curSetIndex==4" :class="curSetIndex==4?'none':''">
						</div>
						<div :class="['center_item_name',curSetIndex==4?'select':'un_select']">
							{{$t('set.bindingPhone')}}
						</div>
						<img src="../../assets/mycenter/active_item.png" class="jiantou" v-if="curSetIndex==4">
						<img src="../../assets/mycenter/item.png" class="jiantou" v-else>
					</div> -->
					<div  class="center_item" @click="itemClick2(5)">
						<div class="center_item_img" v-show="curSetIndex==5" :class="curSetIndex==5?'none':''">
							<!-- <img src="../../assets/person/item_select.png"> -->
						</div>
						<div :class="['center_item_name',curSetIndex==5?'select':'un_select']">
							{{$t('set.bindingEmail')}}
						</div>
						<img src="../../assets/mycenter/active_item.png" class="jiantou" v-if="curSetIndex==5">
						<img src="../../assets/mycenter/item.png" class="jiantou" v-else>
					</div>
					<div class="center_item" @click="itemClick2(6)">
						<div class="center_item_img" v-show="curSetIndex==6" :class="curSetIndex==6?'none':''">
							<!-- <img src="../../assets/person/item_select.png"> -->
						</div>
						<div :class="['center_item_name',curSetIndex==6?'select':'un_select']">
							{{$t('set.adviceFeedback')}}
						</div>
						<img src="../../assets/mycenter/active_item.png" class="jiantou" v-if="curSetIndex==6">
						<img src="../../assets/mycenter/item.png" class="jiantou" v-else>
					</div>
					<div class="center_item" @click="itemClick2(7)">
						<div class="center_item_img" v-show="curSetIndex==7" :class="curSetIndex==7?'none':''">
							<!-- <img src="../../assets/person/item_select.png"> -->
						</div>
						<div :class="['center_item_name',curSetIndex==7?'select':'un_select']">
							{{$t('set.aboutUs')}}
						</div>
						<img src="../../assets/mycenter/active_item.png" class="jiantou" v-if="curSetIndex==7">
						<img src="../../assets/mycenter/item.png" class="jiantou" v-else>
					</div>
					<!-- <div class="center_item" @click="itemClick2(8)">
						<div class="center_item_img" v-show="curSetIndex==8" :class="curSetIndex==6?'none':''">
						</div>
						<div :class="['center_item_name',curSetIndex==8?'select':'un_select']">
							{{$t('set.contactUs')}}
						</div>
						<img src="../../assets/mycenter/active_item.png" class="jiantou" v-if="curSetIndex==8">
						<img src="../../assets/mycenter/item.png" class="jiantou" v-else>
					</div> -->
					<!-- <div class="center_item" @click="itemClick2(8)">
						<div class="center_item_img" v-show="curSetIndex==8">
							<img src="../../assets/person/item_select.png">
						</div>
						<div :class="['center_item_name',curSetIndex==8?'select':'un_select']">
							{{$t('contactUs')}}
						</div>
					</div> -->
				</div>
			</div>
			<div class="person_right">
				<router-view></router-view>
			</div>
		</div>

	</div>
</template>

<script>
	export default {
		data() {
			return {
				list: [],
				curCenterIndex: 0, //个人中心中当前选中项 
				curSetIndex: -1, //设置中当前选中
				personList: [], //个人中心
				setList: [], //设置
				isBindingModal: '', //发布信息时是否验证
			}
		},
		mounted() {
			this.getPersonAndSetData();

			// 监听localstorage里面的数据
			window.addEventListener("setItemEvent", (e) => {
				if (e.key === "lang") {
					this.getPersonAndSetData();
				}
			})

			// 保证刷新后，当前选中项不会被重置
			if (Object.prototype.hasOwnProperty.call(this.$route.query, "curCenterIndex")) {
				this.curSetIndex = -1;
				this.curCenterIndex = this.$route.query.curCenterIndex;
			} else {
				this.curCenterIndex = -1;
				this.curSetIndex = this.$route.query.curSetIndex;
			}

			this.getUserInfo();
		},
		methods: {
			// 个人信息
			getUserInfo() {
				this.$http.userInfo().then(res => {
					if (res.code == 1) {
						// 是否完善
						this.isBindingModal = res.data.authentication == 0 ? true : false;
					} else if (res.code == -201) {
					
				} else {
						this.$message.error(res.msg);
					}
				})
			},
			// 个人中心和设置数据
			getPersonAndSetData() {
				this.personList = [this.$t('title.personInfor'),
						this.$t('other.myMessage'), 
						this.$t('title.memberCenter'), 
						this.$t('inforOrder'), 
						this.$t('adOrder'), 
						this.$t('vipOrder'), 
						this.$t('title.myRelease'), 
						this.$t('myAd'),
						this.$t('title.myCollect'), 
						this.$t('talent'), 
						this.$t('other.helpCenter')
				];

				console.log(this.personList,'接口')
			},
			// 个人中心点击
			itemClick(index) {
				console.log(this.isBindingModal)
				this.curCenterIndex = index;
				this.curSetIndex = -1;
				switch (index * 1) {
					case 0:
						// 个人信息
						this.$router.push({
								path: '/personInfor',
								query: {
									curCenterIndex: 0,
								},
							})
						// if (this.isBindingModal) {
						// 	this.$router.push({
						// 		path: '/verifyPage',
						// 		query: {
						// 			curCenterIndex: 0,
						// 		},
						// 	})
						// } else {
						// 	this.$router.push({
						// 		path: '/personInfor',
						// 		query: {
						// 			curCenterIndex: 0,
						// 		},
						// 	})
						// }
						break;
					case 1:
						// 我的信息
						this.$router.push({
							path: '/myMessage',
							query: {
								curCenterIndex: 1,
							}
						})
						break;
					case 2:
						// 会员中心
						this.$router.push({
							path: '/vip',
							query: {
								curCenterIndex: 2,
							}
						})
						break;
					case 3:
						// 信息订单
						this.$router.push({
							path: '/inforOrder',
							query: {
								curCenterIndex: 3,
							}
						})
						break;
					case 4:
						// 广告订单
						this.$router.push({
							path: '/adOrder',
							query: {
								curCenterIndex: 4,
							}
						})
						break;
					case 5:
						this.$router.push({
							path: '/vipOrder',
							query: {
								curCenterIndex: 5,
							}
						})
						break;
					case 6:
						// 我的发布
						this.$router.push({
							path: '/myRelease',
							query: {
								curCenterIndex: 6,
							}
						})
						break;
					case 7:
						// 我的广告
						this.$router.push({
							path: '/myAd',
							query: {
								curCenterIndex: 7,
							}
						})
						break;
					case 8:
						// 我的收藏
						this.$router.push({
							path: '/collect',
							query: {
								curCenterIndex: 8,
							}
						})
						break;
					case 9:
						// 人才招聘
						this.$router.push({
							path: '/talentRecruitment',
							query: {
								curCenterIndex: 9,
							}
						})
						break;
					case 10:
						// 帮助中心
						this.$router.push({
							path: '/helpCenter',
							query: {
								curCenterIndex: 10,
							}
						})
						break;
				}
			},
			// 设置中当前选中
			itemClick2(index) {
				this.curSetIndex = index;
				this.curCenterIndex = -1;

				switch (index * 1) {
					case 0:
						this.$router.push({
							path: '/editPassword1',
							query: {
								curSetIndex: 0,
							}
						})
						break;
					case 1:
						this.$router.push({
							path: '/editPassword2',
							query: {
								curSetIndex: 1,
							}
						})
						break;
					case 2:
						this.$router.push({
							path: '/editBindingPhone',
							query: {
								curSetIndex: 2,
							}
						})
						break;
					case 3:
						this.$router.push({
							path: '/editBindingEmail',
							query: {
								curSetIndex: 3,
							}
						})
						break;
					case 4:
						this.$router.push({
							path: '/bindingPhone',
							query: {
								curSetIndex: 4,
							}
						})
						break;
					case 5:
						this.$router.push({
							path: '/bindingEmail',
							query: {
								curSetIndex: 5,
							}
						})
						break;
					case 6:
						this.$router.push({
							path: '/adviceFeedback',
							query: {
								curSetIndex: 6,
							}
						})
						break;
					case 7:
						this.$router.push({
							path: '/aboutUs',
							query: {
								curSetIndex: 7,
							}
						})
						break;
					// case 8:
					// 	this.$router.push({
					// 		path: '/contactUs',
					// 		query: {
					// 			curSetIndex: 8,
					// 		}
					// 	})
					// 	break;
					
				}
			},
		}
	}
</script>

<style scoped="" lang="less">
	.page {
		background: #F6F7F9;
		padding: 24px 0 64px;
	}

	.person {
		width: 100%;
		margin: 0 auto;
		display: flex;

		.person_left {
			width: 280px;
			background-color: #FFFFFF;
			padding-bottom: 32px;
		    box-shadow: 2px 2px 4px rgba(0,0,0,0.12);


			.person_title {
				height: 56px;
				display: flex;
				align-items: center;
				justify-content: center;
				// background-image: url(../../assets/person/title_bg.png);
				// background-repeat: no-repeat;
				background-size: 100% 100%;
				background: #F7F7F7;


				.person_img {
					margin-right: 8px;
					display: flex;
					align-items: center;

					img {
						width: 17px;
						height: 17px;
					}
				}

				.person_title_name {
					font-size: 18px;
					color: #333333;
					font-weight: bold;
				}
			}
		}

		.person_right {
			width: 84%;
			margin-left: 24px;
			background-color: #FFFFFF;
		}
	}

	.all_center {
		margin-top: 32px;

		.center_item {
			display: flex;
			margin-bottom: 32px;
			justify-content: space-between;
			padding: 0 16px 0 50px;
			white-space: nowrap;
			word-wrap:break-word !important;



			.center_item_img {
				display: flex;
				align-items: center;
				
			

				img {
					width: 24px;
					height: 12px;
				}
			}

			.none{
				display: none;
			}

			.jiantou{
				width: 16px;
				height: 16px;
			}

			.center_item_name {
				margin-left: 8px;
				font-size: 14px;
				word-break: break-all;

				&:hover {
					cursor: pointer;
					color: #F39800FF;
				}
			}

			.select {
				color: #F39800FF;
				font-weight: bold;
			}

			.un_select {
				color: #666666;
				font-weight: 400;
			}
		}
	}

	.set {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 56px;
		line-height: 56px;
		background: #F7F7F7;

		.set_img {
			display: flex;
			align-items: center;
			margin-right: 8px;

			img {
				width: 17px;
				height: 17px;
			}
		}

		.set_title {
			font-size: 18px;
			font-weight: bold;
		}
	}
</style>
